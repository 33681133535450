import React, { useContext, useReducer } from 'react';
import ProjectContext from './ProjectContext';
import ProjectReducer from './ProjectReducer';
import { SET_PROJECT_DATA, SET_PROJECT_INIT } from '../types';
import { INITIAL_PROJECT_STATE } from '../../config/constants';

const ProjectProvider = ({ children }) => {
    const initialState = { ...INITIAL_PROJECT_STATE };

    const [projectState, dispatch] = useReducer(ProjectReducer, initialState);

    const initProjectData = () => {
        dispatch({
            type: SET_PROJECT_INIT,
            payload: initialState,
        });
    };

    const setProjectData = (data) => {
        dispatch({
            type: SET_PROJECT_DATA,
            payload: data,
        });
    };

    return (
        <ProjectContext.Provider
            value={{
                initialState,
                initProjectData,
                projectState,
                setProjectData,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export const useProjectContext = () => useContext(ProjectContext);

export default ProjectProvider;
