import Select from 'react-select';
import React, { useEffect, useState } from 'react';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        padding: '10px 20px',
        backgroundColor: state.isSelected ? '#1A2238' : state.isFocused ? '#3F5266' : '#242F4E',
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.selectProps.isAlert ? '#FD7575' : '#242F4E',
        color: '#fff',
        border: 'none',
        '&:hover': {
            borderColor: 'hsl(0, 0%, 70%)',
        },
        borderRadius: '13px',
        padding: '.4rem 0 .4rem 1rem',
        margin: '.5rem 0',
        height: '48px',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        alignItems: state.isDisabled ? 'center' : 'stretch',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const color = '#fff';

        return { ...provided, color, opacity, transition };
    },
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#242F4E',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#687C92',
        title: '',
    }),
    input: (provided) => ({
        ...provided,
        color: '#fff',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#687C92',
        '&:hover': {
            color: '#687C92',
            opacity: '.8',
        },
        display: state.selectProps.isDropdown ? 'block' : 'none',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: '#687C92',
        '&:hover': {
            color: '#687C92',
            opacity: '.8',
        },
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
};

const CustomSelect = ({
    options,
    placeHolder,
    changeHandler,
    isAlert,
    defaultValue = '',
    isDisabled = false,
    isClearable = false,
    isDropdown = true,
}) => {
    const [selValue, setSelectedValue] = useState(defaultValue);

    const selectionChanged = (selected) => {
        setSelectedValue(selected);
        changeHandler(selected);
    };

    useEffect(() => {
        setSelectedValue(defaultValue);
    }, [defaultValue]);

    return (
        <Select
            value={selValue}
            onChange={(selected) => selectionChanged(selected)}
            className="basic-single"
            classNamePrefix="select"
            isDisabled={isDisabled}
            isLoading={false}
            isClearable={isClearable}
            isRtl={false}
            isSearchable={true}
            name="color"
            options={options}
            styles={customStyles}
            placeholder={placeHolder}
            isAlert={isAlert}
            isDropdown={isDropdown}
        />
    );
};

export default CustomSelect;
