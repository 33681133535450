import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useEffect, useMemo, useState } from 'react';
import { Timeline } from '.';
import { SALE_TYPE_PRIVATE } from '../../config/constants';
import { useSaleContext } from '../../context';

const PrivateTimeline = () => {
    const [info, setInfo] = useState([]);
    const { saleState } = useSaleContext();

    const isDataReady = useMemo(() => {
        return saleState.type === SALE_TYPE_PRIVATE && saleState.id;
    }, [saleState]);

    useEffect(() => {
        if (!isDataReady) return;

        let timelineInfo = [
            {
                title: 'Draft',
                pass: true,
            },
            {
                title: 'Published on UI',
                pass: false,
            },
            {
                title: 'Sale deployed on blockchain',
                pass: false,
            },
            {
                title: 'Sale started',
                pass: false,
            },
            {
                title: 'Vesting created on blockchain',
                pass: false,
            },
        ];
        const currentTime = new Date();
        if (saleState?.publishAt && currentTime >= new Date(saleState.publishAt * 1000)) timelineInfo[1].pass = true;
        if (timelineInfo[1].pass && saleState?.saleAddress) timelineInfo[2].pass = true;
        if (
            saleState?.saleAddress &&
            saleState?.rounds[0]?.startsAt &&
            currentTime >= new Date(saleState.rounds[0].startsAt * 1000)
        )
            timelineInfo[3].pass = true;
        if (
            saleState?.vestingAddress &&
            saleState?.claimingStartsAt &&
            currentTime >= new Date(saleState.claimingStartsAt * 1000)
        )
            timelineInfo[4].pass = true;

        setInfo(timelineInfo);
        // eslint-disable-next-line
    }, [isDataReady]);

    return (
        <SkeletonTheme baseColor="#070D1D22" highlightColor="#070D1D80">
            {info.length === 0 ? (
                <Skeleton height={64} borderRadius={12} />
            ) : (
                <div className="horizontal-timeline-wrapper">
                    {info.map((item, index) => (
                        <Timeline
                            timelineData={item}
                            timelineDataIndex={index}
                            key={index}
                            timelines={info}
                            type="private"
                        />
                    ))}
                </div>
            )}
        </SkeletonTheme>
    );
};

export default PrivateTimeline;
