import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import React, { useState, useEffect, useRef } from 'react';
import Etherscan from '../../assets/svg/etherscan.svg';
import CopyClipboard from '../../assets/svg/copyclipboard.svg';
import { ADDR_SCAN_RUL } from '../../web3/connectors';
import ReactTooltip from 'react-tooltip';

const SimpleInput = ({
    className,
    prefix = '',
    inputRef,
    placeHolder,
    onChange,
    maxLength = 0,
    isDisabled,
    isNumber = false,
    isReadOnly = false,
    hasScan = false,
    hasCopy = false,
    isProtected = false,
    protectionInfo,
    ...rest
}) => {
    const [protectionEnabled, setProtectionEnabled] = useState(isProtected);
    const currentElement = useRef(null);
    const [uniqueTipIdentifier, setUniqueTipIdentifier] = useState('');

    useEffect(() => {
        if (isProtected && currentElement) {
            setUniqueTipIdentifier(currentElement.current.parentNode.className + '_tip');
        }
    }, [currentElement, isProtected]);

    return (
        <div ref={currentElement} className={clsx(className, 'simple-input-container')} {...rest}>
            <input
                type={`${isNumber ? 'number' : 'text'}`}
                ref={inputRef}
                placeholder={placeHolder}
                onChange={onChange}
                disabled={isDisabled || protectionEnabled}
                style={prefix === '' ? { padding: '0 0.5rem 0 1.5rem' } : { padding: '0 0.5rem 0 2rem' }}
                {...rest}
                maxLength={isNumber ? 30 : maxLength > 0 ? maxLength : ''}
                readOnly={isReadOnly}
                onWheel={(e) => {
                    if (!isNumber) return;
                    e.currentTarget.blur();
                }}
            />
            <div className="prefix-container" style={prefix === '' ? { left: '1rem' } : { left: '1.5rem' }} {...rest}>
                <span {...rest}>{prefix}</span>
            </div>
            {isProtected && uniqueTipIdentifier ? (
                <>
                    <button
                        data-tip
                        data-for={uniqueTipIdentifier}
                        className="protector-lock"
                        style={hasScan || hasCopy ? { marginRight: '40px' } : null}
                        onClick={() => setProtectionEnabled(!protectionEnabled)}
                    >
                        {protectionEnabled ? 'Unlock' : 'Lock'}
                    </button>

                    <ReactTooltip
                        className="custom-tooltip"
                        place="top"
                        type="dark"
                        effect="float"
                        id={uniqueTipIdentifier}
                    >
                        <span>{protectionInfo}</span>
                    </ReactTooltip>
                </>
            ) : null}
            {hasScan && (
                <div className="address-scan-container">
                    <a
                        href={`${ADDR_SCAN_RUL[process.env.REACT_APP_CHAIN_ID]}${rest['value'] ?? ''}`}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img src={Etherscan} alt="Address scan" />
                    </a>
                </div>
            )}
            {hasCopy && (
                <div
                    className="address-scan-container"
                    onClick={(e) => {
                        navigator.clipboard.writeText(rest['value']);
                        toast.success('Copied!');
                    }}
                >
                    <img src={CopyClipboard} alt="Copy contents" />
                </div>
            )}
        </div>
    );
};

export default SimpleInput;
