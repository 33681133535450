import React from 'react';

const Medium = ({fill}) => {
    return (
        <svg
            width='17'
            height='13'
            viewBox='0 0 17 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M16.6107 2.20247C16.6107 2.20247 15.9156 2.20247 15.9156 2.86716C15.9156 3.53184 15.9156 10.399 15.9156 10.399C15.9156 10.399 15.9156 11.0632 16.6107 11.0632H16.8421V12.8354H11.2831V11.0632H12.2096V2.82268H12.117L9.27763 12.8354H6.77713L3.96369 2.82268H3.87106V11.0632H4.79756V12.8354H0.165039V11.0632H0.396446C0.396446 11.0632 1.09154 11.0632 1.09154 10.3985C1.09154 9.73387 1.09154 2.86666 1.09154 2.86666C1.09154 2.86666 1.09154 2.20247 0.396445 2.20247H0.165039V0.430321H6.31151L8.46739 8.10301H8.53842L10.7142 0.430321H16.8421V2.20247H16.6107Z'
                fill={fill??''}
            />
        </svg>
    );
};

export default Medium;
