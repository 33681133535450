import clsx from 'clsx';
import toast from 'react-hot-toast';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Bookmark from '../../assets/svg/bookmark.svg';
import { useGlobalContext, useModalContext } from '../../context';
import { fetchWrapper } from '../../helpers/fetch-wrapper';
import { getCookie } from '../../helpers/functions';
import { STR_CONNECT_WALLET } from '../../config/constants';

const WalletStatusButton = () => {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const { openModal } = useModalContext();
    const {
        account,
        currentWallet,
        activateInjectedProvider,
        activateWallet,
        logout,
        setWalletStatus,
        setWarningStr,
        authRecaptchaRef,
    } = useGlobalContext();

    const handleDisconnect = () => {
        if (account) {
            logout();
            navigate('/');
        }
    };

    useEffect(() => {
        const onWalletConnect = async () => {
            if (!account) return;

            const prevAddress = localStorage.getItem('prevAdminAddress');
            if (prevAddress && prevAddress.toLowerCase() !== account.toLowerCase()) {
                handleDisconnect();
                return;
            }

            setWarningStr('Wallet authenticating...');

            let hasCookie = getCookie('HasAuthCookie') === 'True';
            if (!hasCookie) {
                try {
                    const nonce = await fetchWrapper.post('/api/User/Nonce/' + account.toLowerCase());
                    if (!nonce) {
                        handleDisconnect();
                        return;
                    }

                    activateInjectedProvider(currentWallet);
                    const signature = await window.ethereum.request({
                        method: 'personal_sign',
                        params: [nonce, account],
                    });

                    const reCaptchaToken = await authRecaptchaRef.current.executeAsync();
                    if (!reCaptchaToken) {
                        toast.error("Can't get valid token. Please try again");
                        handleDisconnect();
                        return;
                    }

                    let errMsg = '';
                    await fetchWrapper
                        .postWithReCaptcha(
                            '/api/Admin/Authenticate',
                            {
                                walletAddress: account.toLowerCase(),
                                signedMessage: signature,
                            },
                            reCaptchaToken
                        )
                        .then((ret) => {
                            hasCookie = getCookie('HasAuthCookie') === 'True';
                            localStorage.setItem('prevAdminAddress', ret);
                        })
                        .catch((e) => {
                            hasCookie = false;
                            errMsg = e.message ?? e;
                        });

                    if (errMsg) {
                        isMounted.current && setWarningStr(errMsg);
                        return;
                    }
                    if (!hasCookie) {
                        isMounted.current && setWarningStr('Please connect an admin wallet!');
                    } else {
                        isMounted.current && setWarningStr(STR_CONNECT_WALLET);
                    }
                } catch (e) {
                    toast.error('Signature request denied, please try to connect your admin wallet again.');
                    handleDisconnect();
                    hasCookie = false;
                }
            }

            if (hasCookie) {
                isMounted.current && setWalletStatus(true);
            }
        };

        onWalletConnect();
        // eslint-disable-next-line
    }, [account]);

    useEffect(() => {
        if (!isMounted.current) return;

        const hasCookie = getCookie('HasAuthCookie') === 'True';
        if (hasCookie && localStorage.getItem('prevAdminAddress')) {
            let prevConnector = localStorage.getItem('prevAdminConnector');
            if (!prevConnector) prevConnector = 'MetaMask';

            activateWallet(prevConnector);
        } else {
            navigate('/');
        }

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`wallet-status-wrapper ${account ? '' : 'not-'}connected`}>
            <button
                className={clsx('btn-wrapper', 'simple-btn-wrapper')}
                onClick={() => {
                    !account ? openModal('connectWallet') : handleDisconnect();
                }}
            >
                <img src={Bookmark} alt="wallet" />
                <span>{!account ? 'Connect Wallet' : `${account.slice(0, 8)}...${account.slice(-4)}`}</span>
            </button>
        </div>
    );
};

export default WalletStatusButton;
