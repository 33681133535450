import React from 'react';

const Web = ({fill}) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M8.4108 0.449585C3.9879 0.449585 0.391113 4.04721 0.391113 8.46843C0.391113 12.8897 3.9879 16.4873 8.4108 16.4873C12.832 16.4873 16.4296 12.8897 16.4296 8.46843C16.4296 4.04721 12.832 0.449585 8.4108 0.449585ZM15.2502 8.46843C15.2502 10.0329 14.719 11.4755 13.8335 12.6282C13.583 12.4327 13.3165 11.904 13.5671 11.3561C13.8202 10.8048 13.8862 9.52842 13.8277 9.03142C13.7726 8.53525 13.5145 7.33995 12.8136 7.32825C12.1137 7.31823 11.6334 7.08685 11.2174 6.25657C10.3545 4.52917 12.837 4.19756 11.9742 3.24198C11.7328 2.97302 10.4848 4.34624 10.3019 2.51611C10.2902 2.38497 10.4147 2.18867 10.5826 1.98486C13.2923 2.89533 15.2502 5.45719 15.2502 8.46843ZM7.47109 1.69668C7.30737 2.01577 6.87552 2.14524 6.61324 2.38497C6.0419 2.90202 5.79632 2.83018 5.48893 3.32635C5.17903 3.82251 4.18002 4.53669 4.18002 4.89587C4.18002 5.25504 4.68454 5.6777 4.9368 5.59585C5.18906 5.51232 5.85479 5.51733 6.24571 5.65432C6.63746 5.79298 9.51506 5.93164 8.5979 8.36402C8.30722 9.1375 7.03339 9.0072 6.69426 10.2877C6.64331 10.4756 6.46706 11.2784 6.4562 11.5407C6.43532 11.9466 6.74354 13.4769 6.35179 13.4769C5.95837 13.4769 4.90004 12.1078 4.90004 11.8597C4.90004 11.6117 4.62607 10.7421 4.62607 9.99702C4.62607 9.25277 3.35809 9.26447 3.35809 8.27464C3.35809 7.38254 4.04554 6.93817 3.89101 6.50966C3.73982 6.08366 2.53198 6.06946 2.02829 6.01684C2.90786 3.73564 4.97689 2.0425 7.47109 1.69668ZM6.25907 14.9587C6.67004 14.7415 6.7118 14.4608 7.08435 14.4466C7.51035 14.4274 7.85699 14.2804 8.33729 14.1743C8.76329 14.0816 9.52592 13.6489 10.1967 13.5938C10.763 13.5479 11.8798 13.623 12.1805 14.1693C11.0988 14.8885 9.80157 15.3078 8.40996 15.3078C7.65903 15.3078 6.93566 15.1834 6.25907 14.9587Z" 
                fill={fill??''}
            />
        </svg>

    );
};

export default Web;
