import React from 'react';

const ArrowLeft = () => {
    return (
        <svg 
            width="19" 
            height="18" 
            viewBox="0 0 19 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M13.7822 1.00169C13.7822 0.200837 12.8877 -0.275109 12.2235 0.172298L0.441338 8.10847C-0.147114 8.50484 -0.147112 9.3709 0.44134 9.76727L12.2235 17.7034C12.8877 18.1508 13.7822 17.6749 13.7822 16.874V14.7953C13.7822 14.243 14.2299 13.7953 14.7822 13.7953H17.6396C18.1918 13.7953 18.6396 13.3475 18.6396 12.7953V5.08048C18.6396 4.5282 18.1918 4.08048 17.6396 4.08048H14.7822C14.2299 4.08048 13.7822 3.63277 13.7822 3.08048V1.00169Z" 
                fill="white"
            />
        </svg>
    );
};

export default ArrowLeft;
