import React from 'react';

const Contents = ({ children }) => {
    return (
        <div className="contents-wrapper">
            <div className="container">{children}</div>
        </div>
    );
};

export default Contents;
