import { toast } from 'react-hot-toast';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactModal from 'react-modal';
import React, { useEffect, useState, useRef } from 'react';
import { SimpleButton } from '../reusable';
import { useModalContext } from '../../context';
import { b2bWrapper } from '../../helpers/b2b-wrapper';

const RequestDetailModal = (props) => {
    const isMounted = useRef(true);
    const [isWaiting, setWaiting] = useState(false);
    const [answers, setAnswers] = useState({ name: '', submitted_at: '', results: [] });
    const { modal, modalData, closeModal } = useModalContext();

    const onClose = () => {
        closeModal('requestDetailModal');
    };

    const parseResponse = (res) => {
        // make definition titles
        setAnswers({ name: '', submitted_at: '', results: [] });
        if (!res?.original?.definition?.fields) return;

        let fIdx = 0;
        const definitions = res.original.definition.fields.map((field) => {
            return { id: field.id, title: field.title };
        });

        res.original.answers.map((answer) => {
            fIdx = definitions.findIndex((ele) => ele.id === answer.field.id);
            if (fIdx === -1) return '';

            switch (answer.type) {
                case 'choices':
                    definitions[fIdx].value = '';
                    if (answer.choices.labels) definitions[fIdx].value = answer.choices.labels.join(', ');
                    if (answer.choices.other)
                        definitions[fIdx].value +=
                            (definitions[fIdx].value.length > 0 ? ', ' : '') + answer.choices.other;
                    break;
                case 'choice':
                    definitions[fIdx].value = answer.choice.label;
                    break;
                case 'url':
                    definitions[fIdx].value = answer.url;
                    break;
                case 'email':
                    definitions[fIdx].value = answer.email;
                    break;
                case 'text':
                    definitions[fIdx].value = answer.text;
                    break;
                default:
                    break;
            }

            return '';
        });

        setAnswers({ name: res.name, submitted_at: res.original.submitted_at, results: definitions });
    };

    useEffect(() => {
        if (!modal.requestDetailModal) return;
        if (!modalData?.requestId) return;
        if (!modalData?.path) return;

        const getDetail = async () => {
            setWaiting(true);

            const result = await b2bWrapper
                .get(`/b2b-api/${modalData.path}/${modalData.requestId}`)
                .then((res) => res)
                .catch((err) => {
                    toast.error(err.message);
                    return null;
                });
            if (!result) {
                setWaiting(false);
                return;
            }

            parseResponse(result);

            setWaiting(false);
        };

        isMounted.current && getDetail();
    }, [modal, modalData]);

    useEffect(() => {
        return () => (isMounted.current = false);
    }, []);

    return (
        <SkeletonTheme baseColor="#070D1D22" highlightColor="#070D1D80">
            <ReactModal
                {...props}
                ariaHideApp={false}
                isOpen={modal.requestDetailModal}
                overlayClassName="modal-overlay"
                className="modal-content request-detail-modal"
                shouldCloseOnOverlayClick={true}
                onRequestClose={onClose}
            >
                <div className="content">
                    {isWaiting ? (
                        <Skeleton height={120} />
                    ) : (
                        <>
                            <div className="answer-header">
                                <span>[{answers.name}'s answer]</span>
                                <span>(submitted at {answers.submitted_at})</span>
                            </div>
                            <div className="answer-wrapper">
                                {answers.results.map((result) => (
                                    <div className="type-form-answers" key={result.id}>
                                        <span>{result.title}</span>
                                        <span>{result.value}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="btn-group">
                                <SimpleButton clickHandler={onClose}>Close</SimpleButton>
                            </div>
                        </>
                    )}
                </div>
            </ReactModal>
        </SkeletonTheme>
    );
};

export default RequestDetailModal;
