import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context';
import { fetchWrapper } from '../../helpers/fetch-wrapper';
import { Header, Contents } from '../layout';
import { WrongNetwork } from '../modals';
import { MEMBERSHIP_INFO } from '../../config/constants';

const Layout = () => {
    const {
        isValidWallet,
        logout,
        setWrongNetwork,
        setContractAddresses,
        setCountries,
        setDefaultRestricts,
        setTiers,
        setWarningStr,
        setAccount,
    } = useGlobalContext();
    const healthRef = useRef(null);
    const isMounted = useRef(true);
    let location = useLocation();
    let navigate = useNavigate();
    useEffect(() => {
        const getBackendHealth = async () => {
            await fetchWrapper
                .get('/api/Health')
                .then((res) => {
                    if (!res?.healthStatus) {
                        navigate('/warning');
                    }
                })
                .catch((e) => {
                    console.log("There's problem on BE side", e.message);
                    navigate('/warning');
                });
        };

        if (location.pathname !== '/warning') {
            if (healthRef.current) clearInterval(healthRef.current);
            healthRef.current = setInterval(async () => {
                await getBackendHealth();
            }, 1000 * 30);

            getBackendHealth();
        }
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (!isValidWallet) return;

        const getDefaultRestricts = async () => {
            await fetchWrapper
                .get('/api/Admin/RestrictedCountriesByDefault')
                .then((res) => {
                    setDefaultRestricts(res);
                })
                .catch((err) => {
                    if (err?.message === 'Admin permission required.') {
                        setWarningStr('Admin permission required.');
                        navigate('/warning');
                    }
                    console.log('error from api/Country', err.message);
                });
        };

        isMounted.current && getDefaultRestricts();
        // eslint-disable-next-line
    }, [isValidWallet]);

    useEffect(() => {
        window.ethereum?.on('chainChanged', function (networkId) {
            if (parseInt(networkId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
                logout();
            } else {
                setWrongNetwork(false);
            }
        });

        window.ethereum?.on('accountsChanged', function (accounts) {
            if (!localStorage.getItem('prevAdminAddress') || accounts.length < 1 || !window.ethereum.isConnected())
                return;

            setAccount(accounts[0]);
        });

        window.ethereum?.on('disconnect', function (err) {
            logout();
        });

        const initContractAddresses = async () => {
            try {
                const saleFactoryAddress = await fetchWrapper.get('/api/Misc/SaleFactoryAddress');
                const vestingFactoryAddress = await fetchWrapper.get('/api/Misc/VestingFactoryAddress');
                const lmPoolFactoryAddress = await fetchWrapper.get('/api/Misc/LMPoolFactoryAddress');

                setContractAddresses({
                    saleFactoryAddress,
                    vestingFactoryAddress,
                    lmPoolFactoryAddress,
                });
            } catch (error) {
                console.error(error.message ?? error);
            }
        };

        const getCountries = async () => {
            await fetchWrapper
                .get('/api/Country')
                .then((res) => {
                    setCountries(res);
                })
                .catch((err) => {
                    console.log('error from api/Country', err.message);
                });
        };

        const getTiersInfo = async () => {
            let tiers = await fetchWrapper
                .get('/api/Membership/Tiers')
                .then((res) => res)
                .catch((e) => {
                    console.log('/api/Membership/Tiers', e.message);
                    return [];
                });

            setTiers([
                ...tiers.sort((a, b) => {
                    if (+MEMBERSHIP_INFO[a.name] < +MEMBERSHIP_INFO[b.name]) return -1;
                    else if (+MEMBERSHIP_INFO[a.name] > +MEMBERSHIP_INFO[b.name]) return 1;
                    return 0;
                }),
            ]);
        };

        isMounted.current && initContractAddresses();
        isMounted.current && getCountries();

        isMounted.current && getTiersInfo();

        return () => {
            isMounted.current = false;
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div id="layout-wrapper">
            <div className="top-mask right" />
            <Header />
            <Contents>
                <Outlet />
            </Contents>
            <WrongNetwork />
        </div>
    );
};

export default Layout;
