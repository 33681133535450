import React, { useContext, useReducer } from 'react';
import SaleContext from './SaleContext';
import SaleReducer from './SaleReducer';
import { SET_SALE_INIT, SET_SALE_DATA, SET_SALE_RESTRICTED } from '../types';
import { INITIAL_SALE_STATE, SALE_TYPE_PRIVATE, SALE_TYPE_TIERBASED } from '../../config/constants';

const SaleProvider = ({ children }) => {
    const initialState = { ...INITIAL_SALE_STATE };

    const [saleState, dispatch] = useReducer(SaleReducer, initialState);

    const { overview, restrictedCountries } = saleState;

    const setSaleData = (data) => {
        dispatch({
            type: SET_SALE_DATA,
            payload: data,
        });
    };

    const setRestricts = (data) => {
        dispatch({
            type: SET_SALE_RESTRICTED,
            payload: data,
        });
    };

    const changeSaleType = (type) => {
        const rounds = [];
        if (type === SALE_TYPE_PRIVATE) {
            rounds.push({
                startsAt: null,
                endsAt: null,
            });
        } else {
            rounds.push(
                {
                    startsAt: null,
                    endsAt: null,
                },
                {
                    startsAt: null,
                    endsAt: null,
                }
            );
        }

        dispatch({
            type: SET_SALE_DATA,
            payload: { type, rounds },
        });
    };

    const initSaleData = (isPrivate = false) => {
        dispatch({
            type: SET_SALE_INIT,
            payload: {
                ...initialState,
                type: isPrivate ? SALE_TYPE_PRIVATE : SALE_TYPE_TIERBASED,
                rounds: isPrivate
                    ? [
                          {
                              startsAt: null,
                              endsAt: null,
                          },
                      ]
                    : [
                          {
                              startsAt: null,
                              endsAt: null,
                          },
                          {
                              startsAt: null,
                              endsAt: null,
                          },
                      ],
            },
        });
    };

    return (
        <SaleContext.Provider
            value={{
                changeSaleType,
                initialState,
                overview,
                saleState,
                initSaleData,
                setSaleData,
                restrictedCountries,
                setRestricts,
            }}
        >
            {children}
        </SaleContext.Provider>
    );
};

export const useSaleContext = () => useContext(SaleContext);

export default SaleProvider;
