import React from 'react';
import ReactTooltip from 'react-tooltip';

const DynamicTooltip = ({ id, tipInfo = '00' }) => {
    return (
        <ReactTooltip
            className="custom-tooltip"
            id={id}
            place={tipInfo.length === 3 ? 'top' : 'right'}
            effect="solid"
            delayHide={100}
        >
            {tipInfo[0] === '1' && <span style={{ color: '#C6E547' }}>1: Required before saving</span>}
            {tipInfo[0] === '2' && <span style={{ color: '#C6E547' }}>2: Required before publishing</span>}
            {tipInfo[0] === '3' && <span style={{ color: '#C6E547' }}>3: Required before whitelist start</span>}
            {tipInfo[0] === '4' && (
                <span style={{ color: '#C6E547' }}>4: Required before sale contract deployment</span>
            )}
            {tipInfo[0] === '5' && (
                <span style={{ color: '#C6E547' }}>5: Required before before claiming creation</span>
            )}
            {tipInfo[1] !== 0 && '\r\n'}
            {tipInfo[1] === '1' && (
                <span style={{ color: '#FF9292' }}>1: Non-modifiable after whitelist application start</span>
            )}
            {tipInfo[1] === '2' && <span style={{ color: '#FF9292' }}>2: Non-modifiable after Sale start</span>}
            {tipInfo[1] === '3' && <span style={{ color: '#FF9292' }}>3: Non-modifiable after Vesting start</span>}
        </ReactTooltip>
    );
};

export default DynamicTooltip;
