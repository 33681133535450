import { SET_POOL_DATA, SET_POOL_INIT, SET_POOL_REWARDS } from '../types';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_POOL_INIT:
            return {
                ...action.payload,
            };
        case SET_POOL_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case SET_POOL_REWARDS:
            const rewards = state.rewards;
            rewards[action.payload.index] = { ...rewards[action.payload.index], ...action.payload.data };
            return {
                ...state,
                rewards,
            };
        default:
            return state;
    }
};

export default reducer;
