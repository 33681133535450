import clsx from 'clsx';
import React from 'react';

const TimelineElement = ({ timelineData, timelineDataIndex, timelines, type }) => {
    const getWidth = () => {
        if (timelineDataIndex === 0 || timelineDataIndex === timelines.length - 1)
            return {
                width: `${100 / (timelines.length * 2 - 2)}%`,
            };

        return {
            width: `${(100 / (timelines.length * 2 - 2)) * 2}%`,
        };
    };

    const getAlignPos = () => {
        if (timelineDataIndex === 0) return 'leftAlign';
        else if (timelineDataIndex === timelines.length - 1) return 'rightAlign';

        return 'centerAlign';
    };

    const getLefterColor = () => {
        if (timelines[timelineDataIndex - 1].pass) return 'Passed';
        return 'Notpassed';
    };

    return (
        <div className={clsx(`${type}-timeline-container`, getAlignPos())} style={getWidth()}>
            <span className="title">{timelineData.title}</span>
            <div className="spot">
                {timelineDataIndex > 0 && <div className={clsx('timeline-bar', getLefterColor())}></div>}
                <div className={clsx('timeline-spot', timelineData.pass ? 'Passed' : 'Notpassed')}></div>
                {timelineDataIndex < timelines.length - 1 && (
                    <div className={clsx('timeline-bar', timelineData.pass ? 'Passed' : 'Notpassed')}></div>
                )}
            </div>
        </div>
    );
};

export default TimelineElement;
