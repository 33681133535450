import { SET_SALE_DATA, SET_SALE_INIT, SET_SALE_RESTRICTED } from '../types';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_SALE_INIT:
            return {
                ...action.payload,
            };
        case SET_SALE_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case SET_SALE_RESTRICTED:
            return {
                ...state,
                restrictedCountries: [...action.payload],
            };
        default:
            return state;
    }
};

export default reducer;
