import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import React from 'react';
import { SimpleButton, SimpleInput } from '../reusable';
import { useModalContext } from '../../context';

const ConfirmModal = (props) => {
    const navigate = useNavigate();
    const { modal, openModal, closeModal, modalData, setModalData } = useModalContext();

    const onClose = () => {
        if (modalData.url) navigate(modalData.url);
        closeModal('confirmModal');
    };

    const onDelete = () => {
        closeModal('confirmModal');
        setModalData({ type: 'notify', url: modalData.url });
        openModal('confirmModal');
    };

    const onSave = () => {
        if (modalData?.type !== 'leave') navigate(modalData.url);
        closeModal('confirmModal');
    };

    return (
        <ReactModal
            {...props}
            ariaHideApp={false}
            isOpen={modal.confirmModal}
            overlayClassName="modal-overlay"
            className="modal-content confirm-modal"
            // shouldCloseOnOverlayClick={true}
            // onRequestClose={onClose}
        >
            {modalData?.type !== 'notify' ? (
                <>
                    <h2 className="header">{modalData?.title}</h2>
                    <div className="content">
                        {modalData?.type === 'delete' && (
                            <div className="btn-group">
                                <SimpleButton className="btn-red" clickHandler={onDelete}>
                                    Delete
                                </SimpleButton>
                                <SimpleButton clickHandler={onClose}>No</SimpleButton>
                            </div>
                        )}
                        {modalData?.type === 'save' && (
                            <div className="btn-group">
                                <SimpleButton className="btn-red" clickHandler={onClose}>
                                    No
                                </SimpleButton>
                                <SimpleButton clickHandler={onSave}>Yes</SimpleButton>
                            </div>
                        )}
                        {modalData?.type === 'leave' && (
                            <div className="btn-group">
                                <SimpleButton className="btn-red" clickHandler={onClose}>
                                    Discard
                                </SimpleButton>
                                <SimpleButton clickHandler={onSave}>No</SimpleButton>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="content">
                    {modalData.initialPassword ? (
                        <>
                            <span>Intial password</span>
                            <SimpleInput isReadOnly={true} hasCopy={true} value={modalData.initialPassword} />
                        </>
                    ) : (
                        <span>
                            An e-mail has been sent to XXX (email address), you need to click the link to delete the
                            sale!
                        </span>
                    )}
                    <SimpleButton clickHandler={onClose}>Ok</SimpleButton>
                </div>
            )}
        </ReactModal>
    );
};

export default ConfirmModal;
