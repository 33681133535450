import {
    DISCONNECT_WALLET,
    SET_WALLET_CONNECTION,
    SET_WALLET_STATUS,
    SET_WRONG_NETWORK,
    SET_CONTRACT_ADDRESSES,
    SET_WARNING_STR,
    SET_ORIGIN_SALE,
    SET_ORIGIN_POOL,
    SET_ORIGIN_PROJECT,
    SET_DEFAULT_RESTRICTS,
    SET_COUNTRIES,
    SET_TIERS_INFO,
    SET_ACCOUNT,
} from '../types';

import { STR_CONNECT_WALLET } from '../../config/constants';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_ACCOUNT:
            return {
                ...state,
                account: action.payload,
            };
        case SET_TIERS_INFO:
            return {
                ...state,
                tiers: [...action.payload],
            };
        case SET_COUNTRIES:
            return {
                ...state,
                countries: [...action.payload],
            };
        case SET_DEFAULT_RESTRICTS:
            return {
                ...state,
                defaultRestricted: [...action.payload],
            };
        case SET_ORIGIN_SALE:
            return {
                ...state,
                originSale: action.payload,
            };
        case SET_ORIGIN_POOL:
            return {
                ...state,
                originPool: action.payload,
            };
        case SET_ORIGIN_PROJECT:
            return {
                ...state,
                originProject: action.payload,
            };
        case SET_WARNING_STR:
            return {
                ...state,
                warningStr: action.payload,
            };
        case SET_WRONG_NETWORK:
            return {
                ...state,
                wrongNetwork: action.payload,
            };
        case DISCONNECT_WALLET:
            return {
                ...state,
                currentWallet: '',
                activeProvider: null,
                account: null,
                isValidWallet: false,
                warningStr: STR_CONNECT_WALLET,
            };
        case SET_WALLET_CONNECTION:
            return {
                ...state,
                currentWallet: action.payload.name,
                activeProvider: action.payload.provider,
                account: action.payload.account,
            };
        case SET_WALLET_STATUS:
            return {
                ...state,
                isValidWallet: action.payload,
            };
        case SET_CONTRACT_ADDRESSES:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
