export const networks = {
    MainNet: 1,
    Ropsten: 3,
    Rinkeby: 4,
    Goerli: 5,
    Kovan: 42,
    Polygon: 137,
    PolygonDev: 138,
    EWC: 246,
    Sepolia: 11155111,
    BaseGoerli: 84531,
};

export const NETWORK_NAME = {
    1: 'MainNet',
    3: 'Ropsten',
    4: 'Rinkeby',
    5: 'Goerli',
    42: 'Kovan',
    137: 'Polygon',
    138: 'PolygonDev',
    246: 'EWC',
    11155111: 'Sepolia',
    84531: 'Base Goerli',
};

export const MEMBERSHIP_INFO = {
    Citizen: 1,
    Officer: 2,
    Ambassador: 3,
    Senator: 4,
};

export const SALE_STATUS = {
    New: -1,
    Open: 0,
    Closed: 1,
    Round1: 2,
    Round2: 3,
    Round2End: 4,
    Claiming: 5,
    ClaimingEnds: 6,
};

export const CONTROL_BUTTON_STATUS = {
    None: 0,
    Approving: 1,
    Contributing: 2,
    Claiming: 3,
};

export const WHITELISTSTATUS = {
    NotSubmitted: 1,
    Submitted: 2,
    Pending: 3,
    Closed: 4,
};

export const ROUND1STATUS = {
    NotContribute_1st: 1,
    ContributeNotMax_1st: 2,
    ContributeMax_1st: 3,
};

export const ROUND2STATUS = {
    NotContribute_2nd: 1,
    ContributeNotMax_2nd: 2,
    ContributeMax_2nd: 3,
};

export const CLAIMSTATUS = {
    SaleComplete: 1,
    ClaimSoon: 2,
    Claim: 3,
    Claimed: 4,
};

export const FIELD_REQUIRED = {
    BeforeSaving: 1,
    BeforePublishing: 2,
    BeforeWhitelist: 3,
    BeforeSaleDeploy: 4,
    BeforeClaimCreation: 5,
};

export const FIELD_NON_MODIFY = {
    BeforeWhitelist: 0,
    AfterWhitelist: 1,
    AfterSale: 2,
    AfterVesting: 3,
};

export const KYC_STATUS_NONE = 'None';
export const KYC_STATUS_UNVERIFIED = 'Unverified';
export const KYC_STATUS_VERIFYING = 'Verifying...';
export const KYC_STATUS_VERIFIED = 'Verified';
export const KYC_STATUS_FAILED = 'Failed';

export const RET_STATUS_OK = 'ok';

export const WHITELIST_STATUS = {
    Whitelist: 1,
    Round: 2,
    Sale: 3,
    Claiming: 4,
};

export const LAPTOP_WIDTH = 1120;
export const PHABLET_WIDTH = 768;
export const MOBILE_WIDTH = 500;

export const SALE_STEP_UNSAVED = 1;
export const SALE_STEP_SAVED = 2;
export const SALE_STEP_PUBLISHED = 3;

export const SALE_TYPE_NONE = 'Other';
export const SALE_TYPE_PRIVATE = 'Private';
export const SALE_TYPE_TIERBASED = 'TierBased';
export const SALE_TYPE_UNLIMITED = 'Unlimited';

export const SALE_STATUS_DRAFT = 'draft';
export const SALE_STATUS_PUBLISHED = 'published';
export const SALE_STATUS_WHITELIST_STARTED = 'whitelist started';
export const SALE_STATUS_WHITELIST_FINISHED = 'whitelist finished';
export const SALE_STATUS_SALE_STARTED = 'sale started';
export const SALE_STATUS_SALE_ENDED = 'sale ended';
export const STR_CONNECT_WALLET = 'Please connect your wallet to continue';

export const LMPoolStatus = {
    Draft: 0,
    Created: 1,
    StakingStarted: 2,
};

export const LMPoolType = {
    Genesis: 'Genesis',
    Internal: 'Internal',
    Mandatory: 'Mandatory',
    Optional: 'Optional',
};

export const LMPoolCategory = {
    Staking: 'Staking',
    Farming: 'Farming',
};

export const MATCH_REGEXP = {
    TOKEN_ADDRESS_REGEXP: /^0x[a-fA-F0-9]{40}$/g,
    URL_REGEXP: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm,
    TELEGRAM_REGEXP: /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/g,
    TWITTER_REGEXP: /(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/g,
    DISCORD_REGEXP: /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/g,
    MEDIUM_REGEXP: /(https:\/\/medium.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/g,
    BINANCE_REGEXP: /(https:\/\/www.binance.com\/)/g,
    BITFINEX_REGEXP: /(https:\/\/www.bitfinex.com\/)/g,
    BITHUMB_REGEXP: /(https:\/\/en.bithumb.com\/)/g,
    BITMART_REGEXP: /(https:\/\/www.bitmart.com\/)/g,
    BITTREX_REGEXP: /(https:\/\/global.bittrex.com\/)/g,
    BYBIT_REGEXP: /(https:\/\/www.bybit.com\/)/g,
    COINBASE_REGEXP: /(https:\/\/www.coinbase.com\/)/g,
    CRYPTO_REGEXP: /(https:\/\/crypto.com\/)/g,
    GATE_REGEXP: /(https:\/\/www.gate.io\/)/g,
    KRAKEN_REGEXP: /(https:\/\/www.kraken.com\/)/g,
    KUCOIN_REGEXP: /(https:\/\/www.kucoin.com\/)/g,
    LIQUID_REGEXP: /(https:\/\/www.liquidmarketplace.io\/)/g,
    MEXC_REGEXP: /(https:\/\/www.mexc.com\/)/g,
    OKEX_REGEXP: /(https:\/\/www.okx.com\/)/g,
    PANCAKESWAP_REGEXP: /(https:\/\/pancakeswap.finance\/)/g,
    UNISWAP_REGEXP: /(https:\/\/app.uniswap.org\/)/g,
    UPBIT_REGEXP: /(https:\/\/([a-z]+\.)?upbit.com\/)/g,
};

export const INITIAL_PROJECT_STATE = {
    id: '',
    name: '',
    logo: null,
    overview: '',
    website: '',
    telegram: '',
    twitter: '',
    medium: '',
    tickerSymbol: '',
    initialTokenCirculation: '',
    publicSalePrice: '',
    totalSupply: '',
    tokenAddress: '',
    longProjectIntroduction: '',
    binanceMarket: '',
    bitfinexMarket: '',
    bithumbMarket: '',
    bitmartMarket: '',
    bittrexMarket: '',
    bybitMarket: '',
    coinbaseMarket: '',
    cryptocomMarket: '',
    // ftxMarket: null,
    gateMarket: '',
    krakenMarket: '',
    kucoinMarket: '',
    liquidMarket: '',
    mexcMarket: '',
    okexMarket: '',
    pancakeSwapMarket: '',
    uniswapMarket: '',
    upbitMarket: '',
    canBeDeleted: false,
    isTokenAddressModifiable: false,
};

export const INITIAL_SALE_STATE = {
    id: '',
    name: '',
    type: SALE_TYPE_PRIVATE,
    projectId: '',
    rounds: [],
    restrictedCountries: [],
    publishAt: null,
    paymentTokenAddress: '',
    tokenAddress: '',
    maximumRaiseAmountInPaymentToken: '',
    minimumRaiseAmountInPaymentToken: '',
    tokenPriceInPaymentToken: '',
    whitelistStartsAt: null,
    whitelistEndsAt: null,
    whitelistedUsersModifiedAt: null,
    overview: '',
    telegramGroupLink: '',
    discordGroupLink: '',
    twitterLink: '',
    linkToTweet: '',
    unlimitedAvailableSpots: 0,
    availableSpots: [],
    question1: '',
    correctAnswer1: '',
    firstIncorrectAnswer1: '',
    secondIncorrectAnswer1: '',
    question2: '',
    correctAnswer2: '',
    firstIncorrectAnswer2: '',
    secondIncorrectAnswer2: '',
    question3: '',
    correctAnswer3: '',
    firstIncorrectAnswer3: '',
    secondIncorrectAnswer3: '',
    otarisFeeAddress: '',
    saleAddress: '',
    vestingAddress: '',
    fee: 0,
    saleBeneficiaryAddress: '',
    saleBlockchainId: '',
    vestingBlockchainId: '',
    claimingStartsAt: '',
    claimingEndsAt: '',
    cliffEndTime: '',
    initialClaim: '',
    minimumContributionLeft: 0,
    releaseInterval: '',
    isSaleAndVestingLinked: false,
    isSaleContractDeployed: false,
    isVestingContractDeployed: false,
    canBeDeleted: false,
};

export const INIT_REWARD_INFO = {
    address: '',
    symbol: '',
    logo: null,
    totalAmount: '',
    currentAmount: '',
    adminUSDValue: '',
};

export const INITIAL_POOL_STATE = {
    id: '',
    name: '',
    category: '',
    type: '',
    projectId: '',
    fee: '',
    feeAddress: '',
    userStakingStartsAt: '',
    firstTokenAddress: '',
    firstTokenSymbol: '',
    firstTokenLogo: null,
    secondTokenAddress: '',
    secondTokenSymbol: '',
    secondTokenLogo: null,
    baseTokenAddress: '',
    baseTokenDecimals: '',
    baseTokenSymbol: '',
    baseTokenLogo: null,
    baseTokenUSDValue: '',
    rewardAccumulationStartsAt: null,
    rewardAccumulationEndsAt: null,
    rewards: [INIT_REWARD_INFO],
    isVisible: false,
};
