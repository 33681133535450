import React from 'react';

const Warning = () => {
    return (
        <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M39.503 0.0781252C37.0434 0.0775588 34.795 1.47346 33.6937 3.67311L0.771418 69.5096C-1.36168 73.7823 1.80512 78.9209 6.58077 78.9216H72.4173C77.1926 78.9204 80.3626 73.7823 78.2292 69.5096L45.3251 3.67311C44.2239 1.47346 41.9623 0.0775452 39.5027 0.0781252H39.503ZM39.503 2.79941C40.9389 2.79913 42.2532 3.60199 42.8961 4.88632L75.8002 70.7228C77.0817 73.2898 75.2862 76.2022 72.4177 76.2028H6.58113C3.7126 76.2023 1.91706 73.2898 3.19857 70.7228L36.1237 4.88632C36.7666 3.60199 38.0679 2.79912 39.5035 2.79941H39.503ZM39.4447 24.7937C36.6506 24.7929 34.3861 27.118 34.3869 29.9234V48.2674C34.3864 51.0724 36.6506 53.3948 39.4447 53.3942C42.2388 53.3948 44.5112 51.0725 44.5106 48.2674L44.5103 29.9234C44.5109 27.1183 42.2385 24.7932 39.4444 24.7937H39.4447ZM39.4447 27.515C40.7412 27.5147 41.7922 28.5603 41.7919 29.9231V48.2671C41.7921 49.6298 40.7412 50.6754 39.4447 50.6751C38.1482 50.6754 37.1051 49.6298 37.1057 48.2671L37.1054 29.9238C37.1051 28.5611 38.1479 27.5155 39.4444 27.5158L39.4447 27.515ZM39.3147 57.4184C36.5809 57.4889 34.3864 59.784 34.3869 62.5452C34.3864 65.3503 36.6506 67.6834 39.4447 67.6828C42.2388 67.6833 44.5112 65.3506 44.5106 62.5452C44.5112 59.7401 42.239 57.4175 39.4447 57.4184C39.4011 57.4184 39.358 57.4172 39.3147 57.4184ZM39.4447 60.1371C40.7412 60.1368 41.7922 61.1824 41.7919 62.5452C41.7921 63.908 40.7412 64.9643 39.4447 64.964C38.1482 64.9643 37.1051 63.908 37.1057 62.5452C37.1054 61.1824 38.1482 60.1368 39.4447 60.1371Z"
                fill="white"
            />
        </svg>
    );
};

export default Warning;
