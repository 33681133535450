/**
 * Constant variables for Set/Unset Global Context
 */
export const DISCONNECT_WALLET = 'DISCONNECT_WALLET';
export const SET_WALLET_CONNECTION = 'SET_WALLET_CONNECTION';
export const SET_WALLET_STATUS = 'SET_WALLET_STATUS';
export const SET_WRONG_NETWORK = 'SET_WRONG_NETWORK';
export const SET_CONTRACT_ADDRESSES = 'SET_CONTRACT_ADDRESSES';
export const SET_WARNING_STR = 'SET_WARNING_STR';
export const SET_ORIGIN_POOL = 'SET_ORIGIN_POOL';
export const SET_ORIGIN_SALE = 'SET_ORIGIN_SALE';
export const SET_ORIGIN_PROJECT = 'SET_ORIGIN_PROJECT';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_DEFAULT_RESTRICTS = 'SET_DEFAULT_RESTRICTS';
export const SET_TIERS_INFO = 'SET_TIERS_INFO';
export const SET_ACCOUNT = 'SET_ACCOUNT';

/**
 * Constant variables for Sale Context
 */
export const SET_SALE_INIT = 'SET_SALE_INIT';
export const SET_SALE_DATA = 'SET_SALE_DATA';
export const SET_SALE_RESTRICTED = 'SET_SALE_RESTRICTED';

/**
 * Constant variables for Project Context
 */
export const SET_PROJECT_INIT = 'SET_PROJECT_INIT';
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA';

/**
 * Constant variables for Pool Context
 */
export const SET_POOL_INIT = 'SET_POOL_INIT';
export const SET_POOL_DATA = 'SET_POOL_DATA';
export const SET_POOL_REWARDS = 'SET_POOL_REWARDS';
