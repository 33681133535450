import React from 'react';

const CustomTextArea = ({ placeHolder, ...rest }) => {
    return (
        <div className={'custom-textarea-wrapper'} {...rest}>
            <textarea placeholder={placeHolder} {...rest} />
        </div>
    );
};

export default CustomTextArea;
