import React, { useContext, useReducer } from 'react';
import PoolContext from './PoolContext';
import PoolReducer from './PoolReducer';
import { SET_POOL_DATA, SET_POOL_INIT, SET_POOL_REWARDS } from '../types';
import { INITIAL_POOL_STATE } from '../../config/constants';

const PoolProvider = ({ children }) => {
    const initialState = { ...INITIAL_POOL_STATE };

    const [poolState, dispatch] = useReducer(PoolReducer, initialState);
    const { isVisible, rewards } = poolState;

    const initPoolData = () => {
        dispatch({
            type: SET_POOL_INIT,
            payload: initialState,
        });
    };

    const setPoolData = (data) => {
        dispatch({
            type: SET_POOL_DATA,
            payload: data,
        });
    };

    const setRewards = (data, index) => {
        dispatch({
            type: SET_POOL_REWARDS,
            payload: { data, index },
        });
    };

    const changePoolCategory = (data) => {
        dispatch({
            type: SET_POOL_DATA,
            payload: { category: data },
        });
    };

    const changePoolType = (data) => {
        dispatch({
            type: SET_POOL_DATA,
            payload: { type: data },
        });
    };

    return (
        <PoolContext.Provider
            value={{
                changePoolCategory,
                changePoolType,
                isVisible,
                initialState,
                initPoolData,
                poolState,
                setPoolData,
                rewards,
                setRewards,
            }}
        >
            {children}
        </PoolContext.Provider>
    );
};

export const usePoolContext = () => useContext(PoolContext);

export default PoolProvider;
