import React from 'react';
import { ConnectWallet } from '../modals';
import { WalletStatusButton } from '../reusable';
import { OtarisFullLogo } from '../../assets/logos';
import { useGlobalContext } from '../../context';
import { Navbar } from '.';

const Header = () => {
    const { account, isValidWallet } = useGlobalContext();

    return (
        <header>
            <div className="header-container">
                <div className="logo-wrapper">
                    <a href="/#">
                        <OtarisFullLogo width={200} height={45} />
                    </a>
                    <div className="divider" />
                    <span className="site-name">Admin Dashboard</span>
                    {account && isValidWallet && (
                        <>
                            <div className="divider" />
                            <Navbar />
                        </>
                    )}
                </div>
                <div className="auth-wrapper">
                    <WalletStatusButton />
                </div>
                <ConnectWallet />
            </div>
        </header>
    );
};

export default Header;
