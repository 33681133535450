// import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React, { useRef } from 'react';
import { formatUTC } from '../../helpers/functions';
import { Calendar } from '../../assets/icons';

// moment.tz.setDefault('America/Los_Angeles');

const CustomDatePicker = ({ onDateChanged, selected, minDate = null, maxDate = null, placeHolder = '', ...rest }) => {
    const calendarRef = useRef();
    const iconClicked = (e) => {
        calendarRef.current.click();
    };

    return (
        <div className="custom-date-picker-wrapper">
            <DatePicker
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy H:mm"
                showTimeInput
                placeholderText={placeHolder}
                popperPlacement="bottom-end"
                // onChangeRaw={(e) => e.preventDefault()}
                {...rest}
                minDate={!minDate ? null : formatUTC(minDate, true)}
                maxDate={!maxDate ? null : formatUTC(maxDate, true)}
                selected={formatUTC(selected, true)}
                onChange={(date) => {
                    if (!selected) {
                        onDateChanged(formatUTC(date));
                        return;
                    }

                    if (maxDate && date > formatUTC(maxDate, true)) return;
                    // if (minDate && date < formatUTC(minDate, true)) return;

                    onDateChanged(formatUTC(date));
                }}
                ref={(scope) => {
                    calendarRef.current = scope?.input;
                }}
            />
            <div className="suffix-container" onClick={iconClicked}>
                <Calendar />
            </div>
        </div>
    );
};

export default CustomDatePicker;
