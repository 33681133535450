import React from 'react';

const RoundRightArrow = ({width, height}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule="evenodd" 
                clipRule="evenodd"
                d="M10.6324 20.3006C13.0459 20.3006 15.3605 19.3418 17.0671 17.6353C18.7737 15.9287 19.7324 13.6141 19.7324 11.2006C19.7324 8.78712 18.7737 6.4725 17.0671 4.76591C15.3605 3.05933 13.0459 2.10059 10.6324 2.10059C8.21895 2.10059 5.90433 3.05933 4.19775 4.76591C2.49117 6.4725 1.53242 8.78712 1.53242 11.2006C1.53242 13.6141 2.49117 15.9287 4.19775 17.6353C5.90433 19.3418 8.21895 20.3006 10.6324 20.3006ZM10.6324 0.800586C13.3907 0.800586 16.036 1.8963 17.9863 3.84668C19.9367 5.79705 21.0324 8.44233 21.0324 11.2006C21.0324 13.9588 19.9367 16.6041 17.9863 18.5545C16.036 20.5049 13.3907 21.6006 10.6324 21.6006C7.87417 21.6006 5.22889 20.5049 3.27851 18.5545C1.32813 16.6041 0.232422 13.9588 0.232421 11.2006C0.232421 8.44234 1.32813 5.79705 3.27851 3.84668C5.22889 1.8963 7.87417 0.800586 10.6324 0.800586ZM6.08242 10.5506C5.91003 10.5506 5.7447 10.6191 5.6228 10.741C5.5009 10.8629 5.43242 11.0282 5.43242 11.2006C5.43242 11.373 5.5009 11.5383 5.6228 11.6602C5.7447 11.7821 5.91003 11.8506 6.08242 11.8506L13.6133 11.8506L10.8222 14.6404C10.7002 14.7624 10.6316 14.928 10.6316 15.1006C10.6316 15.2732 10.7002 15.4387 10.8222 15.5608C10.9443 15.6828 11.1098 15.7514 11.2824 15.7514C11.455 15.7514 11.6206 15.6828 11.7426 15.5608L15.6426 11.6608C15.7032 11.6004 15.7512 11.5287 15.7839 11.4497C15.8167 11.3707 15.8336 11.2861 15.8336 11.2006C15.8336 11.1151 15.8167 11.0304 15.7839 10.9515C15.7512 10.8725 15.7032 10.8008 15.6426 10.7404L11.7426 6.84039C11.6206 6.71833 11.455 6.64977 11.2824 6.64977C11.1098 6.64977 10.9443 6.71833 10.8222 6.84039C10.7002 6.96244 10.6316 7.12798 10.6316 7.30059C10.6316 7.47319 10.7002 7.63873 10.8222 7.76079L13.6133 10.5506L6.08242 10.5506Z" 
                fill="#fff"
            />
        </svg>
    );
};

export default RoundRightArrow;
