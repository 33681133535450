import React from 'react';

const OtarisFullLogo = ({width, height}) => {
    return (
        <svg 
            width={width}
            height={height}
            viewBox="0 0 468 79" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M178.319 61.0088C171.911 65.2136 163.679 66.358 153.269 66.358C142.716 66.358 134.548 65.2021 128.152 60.9337C120.911 56.1942 118.446 48.3972 118.446 39.0714C118.446 29.8236 120.911 21.8734 128.152 17.1369C134.572 12.9349 142.803 11.8599 153.214 11.8599C163.77 11.8599 171.937 13.0158 178.331 17.1369C185.493 21.9486 188.036 29.8236 188.036 39.0714C188.024 48.3972 185.482 56.1942 178.319 61.0088ZM153.269 22.4081C138.938 22.4081 132.39 26.3817 132.39 38.6899C132.39 50.6889 138.798 55.8127 153.19 55.8127C167.445 55.8127 174.069 51.7668 174.069 39.4529C174.081 27.4539 167.687 22.4081 153.281 22.4081H153.269Z" fill="white"/>
            <path d="M212.672 65.2889V23.3388H191.33V12.9351H247.725V23.3388H226.307V65.3005L212.672 65.2889Z" fill="white"/>
            <path d="M296.949 65.2889L291.248 53.9026H262.051L256.516 65.2889H241.789L268.907 12.9351H285.164L312.282 65.2918L296.949 65.2889ZM276.766 24.3185H276.457L266.752 43.97H286.474L276.766 24.3185Z" fill="white"/>
            <path d="M362.12 65.2889L348.022 47.25C346.789 47.3281 345.479 47.328 344.235 47.328H335.555V65.2889H321.92V12.9351H347.576C357.896 12.9351 365.37 13.3917 370.456 17.1399C374.307 19.9662 376.078 24.0179 376.078 29.8267C376.078 36.0169 374.152 40.0686 369.993 42.8978C368.067 44.2734 365.679 45.1895 362.906 45.7241L378.635 65.2918L362.12 65.2889ZM347.637 22.9429H335.555V37.7884H347.789C358.036 37.7884 362.195 36.4879 362.195 30.2197C362.198 23.4024 356.649 22.9429 347.637 22.9429Z" fill="white"/>
            <path d="M392.081 65.2889V12.9351H405.719V65.2889H392.081Z" fill="white"/>
            <path d="M459.18 63.4537C454.482 65.7483 447.704 66.358 441.77 66.358C434.605 66.358 427.672 65.9766 419.968 65.5171V54.3591C426.283 55.0469 434.375 55.6595 439.996 55.6595C444.619 55.6595 448.627 55.3532 450.937 54.8937C453.713 54.2839 454.327 52.8303 454.327 50.6137C454.327 48.244 453.404 47.0216 450.782 46.2557C447.779 45.4928 443.311 44.8801 439.306 44.1923C425.744 41.9758 419.813 38.3084 419.813 28.448C419.813 22.3329 422.048 17.3652 428.132 14.4608C432.755 12.2442 439.073 11.8599 444.619 11.8599C451.027 11.8599 457.409 12.2442 464.266 12.7008V23.5554C458.102 22.8676 450.474 22.3329 444.389 22.3329C441.616 22.3329 438.458 22.4832 436.07 22.9427C433.527 23.5554 432.755 24.8559 432.755 26.9193C432.755 29.1358 433.681 30.3583 436.221 30.9651C439.149 31.7281 443.387 32.2627 447.165 32.8754C460.721 34.9388 467.578 38.4558 467.578 49.0792C467.578 55.4168 465.268 60.4742 459.18 63.4537Z" fill="white"/>
            <path d="M70.4904 19.3161C65.1862 15.8482 58.0207 14.3599 46.5443 14.3599C35.0008 14.3599 27.9023 15.8048 22.77 19.2323C17.0405 23.0672 14.2529 29.558 14.2529 39.0687V39.1496C14.2529 48.6633 17.0405 55.154 22.7758 58.989C27.8965 62.4049 35.0096 63.8614 46.5501 63.8614C58.0265 63.8614 65.192 62.376 70.4962 58.9023C76.2228 55.1107 79.0074 48.6488 79.0074 39.1496V39.0687C79.0074 29.5724 76.2228 23.1106 70.4904 19.3161Z" fill="url(#paint0_linear_5433_15553)"/>
            <path d="M78.3923 7.5452C70.5394 2.40402 61.0261 0.219238 46.5495 0.219238C36.2557 0.219238 24.4355 1.08622 14.8174 7.50185C2.56909 15.6919 0 29.1907 0 39.0685C0 48.9463 2.56909 62.3642 14.8145 70.5687C22.6354 75.7879 32.1952 78.0131 46.7913 78.0131C61.2155 78.0131 70.6763 75.8197 78.401 70.6785L78.4738 70.6294C88.2842 64.0288 93.2592 53.4141 93.2592 39.0801C93.2592 29.1792 90.6785 15.6832 78.3923 7.5452ZM74.4367 64.7946C67.8654 69.0948 59.526 70.9299 46.5495 70.9299C37.3305 70.9299 26.824 70.2103 18.7963 64.8611C11.0512 59.6823 7.12472 51.0357 7.12472 39.1581C7.12472 39.1581 7.12472 39.1321 7.12472 39.1176C7.12472 39.1032 7.12472 39.0916 7.12472 39.0772C7.12472 27.2025 11.0512 18.5587 18.7963 13.3771C26.824 8.01914 37.3305 7.30822 46.5495 7.30822C59.526 7.30822 67.8654 9.14621 74.4367 13.4464C82.2022 18.5905 86.1316 27.214 86.1316 39.0887V39.1696C86.1316 51.027 82.2022 59.6505 74.4367 64.7946Z" fill="url(#paint1_linear_5433_15553)"/>
            <defs>
                <linearGradient id="paint0_linear_5433_15553" x1="25.7953" y1="14.3599" x2="77.5426" y2="39.0703" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3BDCB1"/>
                    <stop offset="1" stopColor="#11A3B7"/>
                </linearGradient>
                <linearGradient id="paint1_linear_5433_15553" x1="16.6232" y1="0.219239" x2="93.4341" y2="33.8325" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3BDCB1"/>
                    <stop offset="1" stopColor="#11A3B7"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default OtarisFullLogo;
