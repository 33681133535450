import React, { useEffect, useState } from 'react';

const Waiting = ({ caption, isShow = false }) => {
    const [delay, setDelay] = useState(0);

    useEffect(() => {
        const intervalFunc = setInterval(() => {
            setDelay((prev) => (prev + 1) % 5);
        }, [500]);

        return () => clearInterval(intervalFunc);
    });

    return (
        <div className={`waiting-wrapper ${isShow ? 'show-wrapper' : ''}`}>
            <span>{`${caption}${Array(delay).fill('.').join('')}`}</span>
        </div>
    );
};

export default Waiting;
