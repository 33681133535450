import ReactModal from 'react-modal';
import React, { useState } from 'react';
import { SimpleButton } from '../reusable';
import { useModalContext } from '../../context';

const PublishModal = (props) => {
    const { modal, modalData, closeModal } = useModalContext();
    const [strState, setString] = useState('');

    const onClose = () => {
        if (props.onResult) props.onResult(false);
        closeModal('publishModal');
    };

    const onPublish = () => {
        if (modalData.type !== 'publish' && props.onResult) props.onResult(true);
        closeModal('publishModal');
    };

    const getCaption = () => {
        if (modalData?.type === 'publish')
            return 'Are you sure you want to publish this sale on the blockchain? Once you do you will not be able to change the yellow marked parameters.';
        // if (modalData?.type === 'acceptAccount') return 'Are you sure you want to create this account?';
        // if (modalData?.type === 'hideRequest') return 'Are you sure you want to hide this request?';
        return `Are you sure you want to completely delete the ${
            modalData?.type === 'delete' ? 'sale' : 'project'
        } from the web application?`;
    };

    const getPlaceHolder = () => {
        if (modalData?.type !== 'delete' && modalData?.type !== 'deleteProject') return 'Type: “I understand”';
        return 'Type: “Delete”';
    };

    const isBtnDisabled = () => {
        if (modalData?.type !== 'delete' && modalData?.type !== 'deleteProject') return strState !== 'I understand';

        return strState !== 'Delete';
    };

    return (
        <ReactModal
            {...props}
            ariaHideApp={false}
            isOpen={modal.publishModal}
            overlayClassName="modal-overlay"
            className="modal-content publish-modal"
        >
            <div className="content">
                <span>{getCaption()}</span>
                <span className="warning">THIS ACTION IS IRREVERSIBLE!</span>
                <input placeholder={getPlaceHolder()} onChange={(e) => setString(e.currentTarget.value)} />
                <div className="btn-group">
                    <SimpleButton className="btn-red" clickHandler={onClose}>
                        Discard
                    </SimpleButton>
                    <SimpleButton clickHandler={onPublish} isDisabled={isBtnDisabled()}>
                        Ok
                    </SimpleButton>
                </div>
            </div>
        </ReactModal>
    );
};

export default PublishModal;
