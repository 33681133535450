import React, { useContext, useReducer, useRef } from 'react';
import { ethers } from 'ethers';
import GlobalContext from './GlobalContext';
import GlobalReducer from './GlobalReducer';
import { FIELD_NON_MODIFY, INITIAL_POOL_STATE, STR_CONNECT_WALLET } from '../../config/constants';
import {
    SET_ACCOUNT,
    DISCONNECT_WALLET,
    SET_WALLET_CONNECTION,
    SET_WALLET_STATUS,
    SET_WRONG_NETWORK,
    SET_CONTRACT_ADDRESSES,
    SET_WARNING_STR,
    SET_ORIGIN_SALE,
    SET_ORIGIN_POOL,
    SET_ORIGIN_PROJECT,
    SET_COUNTRIES,
    SET_DEFAULT_RESTRICTS,
    SET_TIERS_INFO,
} from '../types';
import { fetchWrapper } from '../../helpers/fetch-wrapper';
import { INITIAL_PROJECT_STATE, INITIAL_SALE_STATE, LMPoolStatus } from '../../config/constants';
import ReCaptchaV2 from 'react-google-recaptcha';

const GlobalProvider = ({ children }) => {
    const initialState = {
        currentWallet: '',
        activeProvider: null,
        account: null,
        isValidWallet: false,
        sales: [],
        wrongNetwork: false,
        saleFactoryAddress: '',
        vestingFactoryAddress: '',
        lmPoolFactoryAddress: '',
        warningStr: STR_CONNECT_WALLET,
        tiers: [],
        originPool: { ...INITIAL_POOL_STATE },
        originProject: { ...INITIAL_PROJECT_STATE },
        originSale: { ...INITIAL_SALE_STATE },
        projects: [],
        countries: [],
        defaultRestricted: [],
    };

    const authRecaptchaRef = useRef();

    const [state, dispatch] = useReducer(GlobalReducer, initialState);

    const {
        account,
        currentWallet,
        activeProvider,
        isValidWallet,
        wrongNetwork,
        saleFactoryAddress,
        vestingFactoryAddress,
        lmPoolFactoryAddress,
        warningStr,
        originSale,
        originProject,
        originPool,
        countries,
        defaultRestricted,
        tiers,
    } = state;

    const setAccount = (data) => {
        dispatch({
            type: SET_ACCOUNT,
            payload: data,
        });
    };

    const setTiers = (data) => {
        dispatch({
            type: SET_TIERS_INFO,
            payload: data,
        });
    };

    const setCountries = (data) => {
        dispatch({
            type: SET_COUNTRIES,
            payload: data,
        });
    };

    const setDefaultRestricts = (data) => {
        dispatch({
            type: SET_DEFAULT_RESTRICTS,
            payload: data,
        });
    };

    const setOriginPool = (data) => {
        dispatch({
            type: SET_ORIGIN_POOL,
            payload: data,
        });
    };

    const setOriginSale = (data) => {
        dispatch({
            type: SET_ORIGIN_SALE,
            payload: data,
        });
    };

    const getCurrentPoolStep = () => {
        const currentDateTime = new Date();
        if (originPool.id && currentDateTime >= new Date(originPool.rewardStartsAt * 1000))
            return LMPoolStatus.StakingStarted;

        if (originPool.id) return LMPoolStatus.Created;

        return LMPoolStatus.Draft;
    };

    const getNonModifiableStep = () => {
        const currentDateTime = new Date();
        if (
            originSale.vestingAddress &&
            originSale.claimingStartsAt &&
            currentDateTime >= new Date(originSale.claimingStartsAt * 1000)
        )
            return FIELD_NON_MODIFY.AfterVesting;
        if (
            originSale.saleAddress &&
            originSale.rounds.length > 0 &&
            originSale.rounds[0].startsAt &&
            currentDateTime >= new Date(originSale.rounds[0].startsAt * 1000)
        )
            return FIELD_NON_MODIFY.AfterSale;

        if (originSale.whitelistStartsAt && currentDateTime >= new Date(originSale.whitelistStartsAt * 1000))
            return FIELD_NON_MODIFY.AfterWhitelist;
        // add whitelist step here

        return FIELD_NON_MODIFY.BeforeWhitelist;
    };

    const setWarningStr = (val) => {
        dispatch({
            type: SET_WARNING_STR,
            payload: val,
        });
    };

    const accountChangedHandler = async (provider, name) => {
        const account = await provider.getSigner().getAddress();
        // This chan id line below is only set for Metamask and Coinbase Wallet extensions.
        // Every different wallet should be handled here.
        // Metamask chain id ==> provider._network.chainId
        // Coinbase chain id ==> provider.provider.getChainId()
        const chainId = provider.connection.url.includes('metamask')
            ? provider._network.chainId
            : provider.provider.getChainId();

        if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
            setWrongNetwork(true);
            return;
        }

        dispatch({
            type: SET_WALLET_CONNECTION,
            payload: { provider, account, name },
        });

        localStorage.setItem('adminWalletConnecting', false);
    };

    const continueWalletConnection = async () => {
        const name = localStorage.getItem('prevAdminConnector');
        let provider = activateInjectedProvider(name);
        const account = await provider.getSigner().getAddress();

        dispatch({
            type: SET_WALLET_CONNECTION,
            payload: { provider, account, name },
        });

        localStorage.setItem('adminWalletConnecting', false);
    };

    const activateWallet = async (name, closeModal) => {
        try {
            localStorage.setItem('prevAdminConnector', name);
            localStorage.setItem('adminWalletConnecting', true);
            let provider = activateInjectedProvider(name);
            provider.send('eth_requestAccounts', []).then(async () => {
                await accountChangedHandler(provider, name);
            });
        } catch (error) {
            console.log(error);
        } finally {
            if (closeModal) {
                closeModal();
            }
        }
    };

    const logout = () => {
        localStorage.setItem('adminWalletConnecting', '');
        localStorage.setItem('prevAdminAddress', '');
        localStorage.setItem('prevAdminConnector', '');

        dispatch({
            type: DISCONNECT_WALLET,
        });

        return fetchWrapper
            .post('/api/User/Logout')
            .then((res) => {
                console.log("Admin's AuthToken deleted");
            })
            .catch((msg) => {
                console.log(msg);
            });
    };

    const setWalletStatus = (status) => {
        dispatch({
            type: SET_WALLET_STATUS,
            payload: status,
        });
    };

    const setWrongNetwork = (value) => {
        dispatch({
            type: SET_WRONG_NETWORK,
            payload: value,
        });

        if (value === false && localStorage.getItem('adminWalletConnecting') === 'true') continueWalletConnection();
    };

    const setContractAddresses = (value) => {
        dispatch({
            type: SET_CONTRACT_ADDRESSES,
            payload: value,
        });
    };

    const switchNetwork = async () => {
        const chainId = `0x${parseInt(process.env.REACT_APP_CHAIN_ID, 10).toString(16)}`;
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [
                    {
                        chainId,
                    },
                ],
            });
        } catch (e) {
            // This error code "4902" indicates that the chain has not been added to MetaMask.
            // Chain Id '0x14a33' indicates that the chain is Base Goerli.
            if (e.code === 4902 && chainId === '0x14a33') {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId,
                                chainName: 'Base Goerli',
                                nativeCurrency: {
                                    name: 'Goerli Ether',
                                    symbol: 'ETH',
                                    decimals: 18,
                                },
                                rpcUrls: ['https://goerli.base.org'],
                                blockExplorerUrls: ['https://goerli.basescan.org'],
                            },
                        ],
                    });
                } catch (addError) {
                    console.error(addError);
                }
            }
        }
    };

    const setOriginProject = (data) => {
        dispatch({
            type: SET_ORIGIN_PROJECT,
            payload: data,
        });
    };

    const activateInjectedProvider = (providerName) => {
        const { ethereum } = window;

        if (!ethereum?.providers) {
            return new ethers.providers.Web3Provider(window.ethereum);
        }

        let provider;
        switch (providerName) {
            case 'Coinbase Wallet':
                provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
                break;
            case 'MetaMask':
                provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
                break;
            default:
                return;
        }
        if (provider) ethereum.setSelectedProvider(provider);

        return new ethers.providers.Web3Provider(provider);
    };

    return (
        <GlobalContext.Provider
            value={{
                account,
                setAccount,
                currentWallet,
                activeProvider,
                activateWallet,
                activateInjectedProvider,
                getCurrentPoolStep,
                getNonModifiableStep,
                originPool,
                setOriginPool,
                originSale,
                setOriginSale,
                logout,
                isValidWallet,
                setWalletStatus,
                wrongNetwork,
                setWrongNetwork,
                switchNetwork,
                setContractAddresses,
                saleFactoryAddress,
                vestingFactoryAddress,
                lmPoolFactoryAddress,
                warningStr,
                setWarningStr,
                authRecaptchaRef,
                originProject,
                setOriginProject,
                countries,
                setCountries,
                defaultRestricted,
                setDefaultRestricts,
                tiers,
                setTiers,
            }}
        >
            <ReCaptchaV2
                sitekey={process.env.REACT_APP_SITE_KEY}
                size="invisible"
                theme="dark"
                ref={authRecaptchaRef}
            />
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobalContext = () => useContext(GlobalContext);

export default GlobalProvider;
