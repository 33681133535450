import { networks } from '../config/constants';

// const chainId = parseInt(process.env.REACT_APP_CHAIN_ID);

// const RPC_URLS = {
//     [networks.MainNet]: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
//     [networks.Ropsten]: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
//     [networks.Rinkeby]: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
//     [networks.Goerli]: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
//     [networks.Kovan]: `https://kovan.infura.iov3/${process.env.REACT_APP_INFURA_ID}`,
//     [networks.Polygon]: 'https://matic-mainnet.chainstacklabs.com',
//     [networks.EWC]: 'https://rpc.energyweb.org/',
//     [networks.Sepolia]: `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
// };

export const ADDR_SCAN_RUL = {
    [networks.MainNet]: `https://etherscan.io/address/`,
    [networks.Ropsten]: `https://ropsten.etherscan.io/address/`,
    [networks.Rinkeby]: `https://rinkeby.etherscan.io/address/`,
    [networks.Goerli]: `https://goerli.etherscan.io/address/`,
    [networks.Kovan]: `https://kovan.etherscan.io//address/`,
    [networks.Polygon]: 'https://polygonscan.com/address/',
    [networks.EWC]: 'https://explorer.energyweb.org/address/',
    [networks.Sepolia]: 'https://sepolia.etherscan.io/address/',
};

// const POLLING_INTERVAL = 12000;
