import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useEffect, useMemo, useState } from 'react';
import { Timeline } from './';
import { usePoolContext } from '../../context';

const PoolTimeline = () => {
    const [info, setInfo] = useState([]);
    const { poolState } = usePoolContext();

    const isDataReady = useMemo(() => {
        return poolState.id;
    }, [poolState]);

    useEffect(() => {
        // if (!isDataReady) return;

        let timelineInfo = [
            {
                title: 'Draft',
                pass: false,
            },
            {
                title: 'Pool deployed on blockchain',
                pass: false,
            },
            {
                title: 'Reward accumulation ONGOING',
                pass: false,
            },
            {
                title: 'Reward accumulation ENDED',
                pass: false,
            },
        ];

        setInfo(timelineInfo);
        // eslint-disable-next-line
    }, [isDataReady]);

    return (
        <SkeletonTheme baseColor="#070D1D22" highlightColor="#070D1D80">
            {info.length === 0 ? (
                <Skeleton height={64} borderRadius={12} />
            ) : (
                <div className="horizontal-timeline-wrapper">
                    {info.map((item, index) => (
                        <Timeline
                            timelineData={item}
                            timelineDataIndex={index}
                            key={index}
                            timelines={info}
                            type="pool"
                        />
                    ))}
                </div>
            )}
        </SkeletonTheme>
    );
};

export default PoolTimeline;
