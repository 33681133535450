import clsx from 'clsx';
import React from 'react';

const Switch = ({ label, onClick, active }) => {
    return (
        <div className={clsx('toggle-switch', active ? 'active' : '')} onClick={onClick}>
            <span>{label}</span>
            <div className="switch-area" />
        </div>
    );
};

export default Switch;
