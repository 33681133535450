import { toast } from 'react-hot-toast';
import ReactModal from 'react-modal';
import React, { useRef } from 'react';
import { SimpleButton } from '../reusable';
import { useModalContext } from '../../context';

const CreateAccountModal = (props) => {
    const { modal, closeModal } = useModalContext();
    const emailRef = useRef();
    const projectRef = useRef();

    const onClose = () => {
        if (props.onResult) props.onResult(false);
        closeModal('createAccountModal');
    };

    const onCreate = async () => {
        if (!emailRef.current.value.trim()) {
            toast.error('Please input email address');
            return;
        }

        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(emailRef.current.value.trim())) {
            toast.error('Invalid email format');
            return;
        }

        if (!projectRef.current.value.trim()) {
            toast.error('Please input project');
            return;
        }

        if (props.onResult) props.onResult(true, emailRef.current.value.trim(), projectRef.current.value.trim());
        closeModal('createAccountModal');
    };

    return (
        <ReactModal
            {...props}
            ariaHideApp={false}
            isOpen={modal.createAccountModal}
            overlayClassName="modal-overlay"
            className="modal-content create-account-modal"
        >
            <div className="content">
                <span>Create new account</span>
                <input placeholder="Email" ref={emailRef} />
                <input placeholder="Project Name" ref={projectRef} />
                <div className="btn-group">
                    <SimpleButton className="btn-red" clickHandler={onClose}>
                        Discard
                    </SimpleButton>
                    <SimpleButton clickHandler={onCreate}>Create</SimpleButton>
                </div>
            </div>
        </ReactModal>
    );
};

export default CreateAccountModal;
