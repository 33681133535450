const MetamaskIcon = () => (
    <svg
        width='34'
        height='34'
        viewBox='0 0 34 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='metamask'
    >
        <path
            d='M32.2125 1.33691L19.0273 11.1297L21.4656 5.35211L32.2125 1.33691Z'
            fill='white'
            fillOpacity='0.6'
        />
        <path
            d='M1.77393 1.33691L14.8531 11.2225L12.5341 5.35211L1.77393 1.33691Z'
            fill='white'
            fillOpacity='0.9'
        />
        <path
            d='M27.4687 24.0366L23.957 29.4167L31.4707 31.4839L33.6306 24.1558L27.4687 24.0366Z'
            fill='white'
            fillOpacity='0.9'
        />
        <path
            d='M0.382812 24.1558L2.52954 31.4839L10.0431 29.4167L6.53148 24.0366L0.382812 24.1558Z'
            fill='white'
            fillOpacity='0.9'
        />
        <path
            d='M9.61863 14.946L7.5249 18.1131L14.9855 18.4444L14.7204 10.4272L9.61863 14.946Z'
            fill='white'
            fillOpacity='0.9'
        />
        <path
            d='M24.3676 14.946L19.1996 10.3345L19.0273 18.4444L26.4746 18.1131L24.3676 14.946Z'
            fill='white'
            fillOpacity='0.9'
        />
        <path
            d='M10.043 29.4165L14.522 27.2301L10.6525 24.2087L10.043 29.4165Z'
            fill='white'
            fillOpacity='0.9'
        />
        <path
            d='M19.4648 27.2301L23.957 29.4165L23.3343 24.2087L19.4648 27.2301Z'
            fill='white'
            fillOpacity='0.9'
        />
        <path
            d='M23.957 29.4166L19.4648 27.2302L19.8226 30.1588L19.7829 31.3911L23.957 29.4166Z'
            fill='white'
        />
        <path
            d='M10.043 29.4166L14.2172 31.3911L14.1907 30.1588L14.522 27.2302L10.043 29.4166Z'
            fill='white'
        />
        <path
            d='M10.0429 29.4167L10.6789 24.0366L6.53125 24.1558L10.0429 29.4167Z'
            fill='white'
            fillOpacity='0.8'
        />
        <path
            d='M23.3208 24.0366L23.9568 29.4167L27.4685 24.1558L23.3208 24.0366Z'
            fill='white'
            fillOpacity='0.8'
        />
        <path
            d='M26.4746 18.113L19.0273 18.4444L19.7164 22.2741L20.8163 19.9683L23.4665 21.1742L26.4746 18.113Z'
            fill='white'
            fillOpacity='0.8'
        />
        <path
            d='M10.5457 21.1742L13.1961 19.9683L14.2826 22.2741L14.9849 18.4444L7.52441 18.113L10.5457 21.1742Z'
            fill='white'
            fillOpacity='0.8'
        />
        <path
            d='M7.5249 18.113L10.6522 24.2088L10.5462 21.1742L7.5249 18.113Z'
            fill='white'
        />
        <path
            d='M23.4665 21.1742L23.334 24.2088L26.4746 18.113L23.4665 21.1742Z'
            fill='white'
        />
        <path
            d='M14.9855 18.4443L14.2832 22.274L15.1578 26.7927L15.3566 20.8428L14.9855 18.4443Z'
            fill='white'
        />
        <path
            d='M19.0272 18.4443L18.6694 20.8296L18.8284 26.7927L19.7163 22.274L19.0272 18.4443Z'
            fill='white'
        />
        <path
            d='M19.7165 22.274L18.8286 26.7927L19.4648 27.23L23.3342 24.2087L23.4667 21.1741L19.7165 22.274Z'
            fill='white'
        />
        <path
            d='M10.5464 21.1741L10.6524 24.2087L14.5219 27.23L15.1579 26.7927L14.2833 22.274L10.5464 21.1741Z'
            fill='white'
        />
        <path
            d='M19.7828 31.391L19.8226 30.1586L19.4913 29.8671H14.4954L14.1907 30.1586L14.2172 31.391L10.043 29.4165L11.5006 30.6091L14.4557 32.6632H19.531L22.4993 30.6091L23.957 29.4165L19.7828 31.391Z'
            fill='white'
            fillOpacity='0.7'
        />
        <path
            d='M32.7693 11.7658L33.8957 6.35921L32.2127 1.33691L19.4648 10.7984L24.3679 14.9462L31.2984 16.9736L32.8356 15.1846L32.173 14.7076L33.2331 13.7403L32.4115 13.1042L33.4716 12.2959L32.7693 11.7658Z'
            fill='white'
            fillOpacity='0.3'
        />
        <path
            d='M0.104492 6.35921L1.23086 11.7658L0.515287 12.2959L1.5754 13.1042L0.767064 13.7403L1.82718 14.7076L1.16461 15.1846L2.68852 16.9736L9.61903 14.9462L14.5221 10.7984L1.77417 1.33691L0.104492 6.35921Z'
            fill='white'
            fillOpacity='0.3'
        />
        <path
            d='M31.2981 16.9735L24.3676 14.946L26.4746 18.113L23.334 24.2088L27.4684 24.1557H33.6303L31.2981 16.9735Z'
            fill='white'
        />
        <path
            d='M9.61906 14.946L2.68856 16.9735L0.382812 24.1557H6.53148L10.6527 24.2088L7.52534 18.113L9.61906 14.946Z'
            fill='white'
        />
        <path
            d='M19.0274 18.4445L19.4647 10.7984L21.4789 5.35205H12.5342L14.5219 10.7984L14.9857 18.4445L15.1447 20.8562L15.158 26.7928H18.8286L18.8551 20.8562L19.0274 18.4445Z'
            fill='white'
        />
    </svg>
);

export default MetamaskIcon;
