import { SET_PROJECT_DATA, SET_PROJECT_INIT } from '../types';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_PROJECT_INIT:
            return {
                ...action.payload,
            };
        case SET_PROJECT_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
