import React from 'react';

const Twitter = ({fill}) => {
    return (
        <svg
            width='18'
            height='14'
            viewBox='0 0 18 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M15.6625 3.30653C15.6699 3.45007 15.6727 3.59539 15.6727 3.73893C15.6727 8.16045 12.1548 13.2572 5.72204 13.2572C3.7458 13.2572 1.90854 12.7034 0.360352 11.7535C0.634597 11.7845 0.912549 11.8005 1.19421 11.8005C2.83412 11.8005 4.34154 11.2653 5.53766 10.3694C4.008 10.342 2.71553 9.37615 2.27081 8.04703C2.4839 8.08514 2.70348 8.10551 2.92862 8.10551C3.24827 8.10551 3.55772 8.06564 3.8505 7.98944C2.25042 7.68109 1.04504 6.32982 1.04504 4.7083C1.04504 4.69412 1.04504 4.68083 1.04504 4.66665C1.51663 4.91741 2.05678 5.06805 2.62936 5.08577C1.69081 4.48501 1.07283 3.46248 1.07283 2.30083C1.07283 1.68855 1.24516 1.11349 1.5472 0.619059C3.27143 2.64374 5.8499 3.97463 8.75634 4.11463C8.69705 3.86919 8.66647 3.61488 8.66647 3.35172C8.66647 1.50513 10.2313 0.00855255 12.1631 0.00855255C13.1693 0.00855255 14.0782 0.413489 14.7166 1.06387C15.5124 0.913236 16.261 0.635008 16.9374 0.252224C16.6761 1.03286 16.1211 1.68855 15.3994 2.10235C16.1072 2.02083 16.7799 1.84273 17.4081 1.57602C16.9392 2.24767 16.3463 2.83602 15.6625 3.30653Z'
                fill={fill??''}
            />
        </svg>
    );
};

export default Twitter;
