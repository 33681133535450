import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import { Layout } from './components/layout';
import { GlobalProvider, ModalProvider } from './context';
import { ConfirmModal } from './components/modals';
import { LoadingBlocksWave } from './assets/loading';
import './App.scss';

const Accounts = lazy(() => import('./components/pages/Accounts'));
const LMPools = lazy(() => import('./components/pages/LMPools'));
const Logs = lazy(() => import('./components/pages/Logs'));
const ProjectCreate = lazy(() => import('./components/pages/ProjectCreate'));
const ProjectEdit = lazy(() => import('./components/pages/ProjectEdit'));
const SaleCreate = lazy(() => import('./components/pages/SaleCreate'));
const SaleEdit = lazy(() => import('./components/pages/SaleEdit'));
const PoolCreate = lazy(() => import('./components/pages/PoolCreate'));
const PoolEdit = lazy(() => import('./components/pages/PoolEdit'));
const Projects = lazy(() => import('./components/pages/Projects'));
const Requests = lazy(() => import('./components/pages/Requests'));
const Quotes = lazy(() => import('./components/pages/Quotes'));
const Sales = lazy(() => import('./components/pages/Sales'));
const Warning = lazy(() => import('./components/pages/Warning'));

function App() {
    return (
        <GlobalProvider>
            <ModalProvider>
                <BrowserRouter>
                    <Suspense
                        fallback={
                            <div className="page-loading">
                                <LoadingBlocksWave width={48} height={48} />
                            </div>
                        }
                    >
                        <Routes>
                            <Route element={<Layout />}>
                                <Route index element={<Projects />} />
                                <Route path="/" element={<Projects />} />
                                <Route path="/accounts" element={<Accounts />} />
                                <Route path="/create/project" element={<ProjectCreate />} />
                                <Route path="/edit/project/:projectId" element={<ProjectEdit />} />
                                <Route path="/sales" element={<Sales />} />
                                <Route path="/create/sale" element={<SaleCreate />} />
                                <Route path="/edit/sale/:saleId" element={<SaleEdit />} />
                                <Route path="/lmpool" element={<LMPools />} />
                                <Route path="/create/lmpool" element={<PoolCreate />} />
                                <Route path="/edit/lmpool/:poolId" element={<PoolEdit />} />
                                <Route path="/logs" element={<Logs />} />
                                <Route path="/requests" element={<Requests />} />
                                <Route path="/quotes" element={<Quotes />} />
                                <Route path="/warning" element={<Warning />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Route>
                        </Routes>
                    </Suspense>
                    <Toaster
                        position="top-right"
                        reverseOrder={false}
                        toastOptions={{
                            duration: 5000,
                            style: {
                                position: 'relative',
                                top: '4rem',
                                right: '1.5rem',
                                margin: '5px 0',
                                padding: '.7rem 1rem .7rem 1.5rem',
                                color: 'white',
                                fontSize: '16px',
                                borderRadius: '20px',
                                border: '2px solid #10172a',
                                boxShadow:
                                    '0px 0px 0px 1.6px #1A2238, -4px -4px 8px rgba(255, 255, 255, 0.1), 4px 8px 8px rgba(1, 7, 19, 0.2)',
                                background: 'linear-gradient(135deg, #35405b 0%, #222c45 100%)',
                            },
                        }}
                    />
                    <ConfirmModal />
                </BrowserRouter>
            </ModalProvider>
        </GlobalProvider>
    );
}

export default App;
