import React from 'react';
import { Link } from 'react-router-dom';
import SortSvg from '../../assets/svg/sort.svg';

const Navigators = [
    {
        caption: 'OTARIS Launch',
        children: [
            { url: '/', caption: 'Projects' },
            { url: '/sales', caption: 'Sales' },
            { url: '/lmpool', caption: 'LM Pools' },
            { url: '/logs', caption: 'Logs' },
        ],
    },
    {
        caption: 'OTARIS Discovery',
        children: [
            { url: '/requests', caption: 'Requests' },
            { url: '/accounts', caption: 'Accounts' },
            { url: '/quotes', caption: 'Quotes' },
        ],
    },
];

const Navbar = () => {
    return (
        <div className="nav-wrapper">
            {Navigators.map((nav, index) => (
                <div className="nav-divider-wrapper" key={index}>
                    <div className="nav-item-wrapper">
                        <div className="nav-item">
                            <span className="nav-caption">{nav.caption}</span>
                            <img src={SortSvg} alt="sort order" />
                        </div>

                        <div className="nested-sub-nav">
                            {nav.children.map((subNav, subIdx) => (
                                <Link to={subNav.url} key={subIdx}>
                                    <span className="nav-caption">{subNav.caption}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                    {index < Navigators.length - 1 && <div className="divider" />}
                </div>
            ))}
        </div>
    );
};

export default Navbar;
