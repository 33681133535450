import React from 'react';
import ReactTooltip from 'react-tooltip';

const DynamicPoolTip = ({ id, tipInfo = '00' }) => {
    return (
        <ReactTooltip
            className="custom-tooltip"
            id={id}
            place={tipInfo.length === 3 ? 'top' : 'right'}
            effect="solid"
            delayHide={100}
        >
            {tipInfo[0] === '1' && <span style={{ color: '#C6E547' }}>1: Required before creating</span>}
            {tipInfo[0] === '2' && <span style={{ color: '#C6E547' }}>2: Required before visible</span>}
            {tipInfo[0] === '3' && (
                <span style={{ color: '#C6E547' }}>3: Required before pool contract deployment</span>
            )}
            {tipInfo[0] === '4' && <span style={{ color: '#C6E547' }}>4: Required before updating pool contract</span>}
            {tipInfo[1] !== 0 && '\r\n'}
            {tipInfo[1] === '1' && <span style={{ color: '#FF9292' }}>1: Non-modifiable after creating</span>}
            {tipInfo[1] === '2' && <span style={{ color: '#FF9292' }}>2: Non-modifiable after staking started</span>}
        </ReactTooltip>
    );
};

export default DynamicPoolTip;
